<template>
  <li class="comment" v-if="comment">
    <p class="comment__author"><strong>{{ userName(comment) }}</strong> - {{ comment.warrantyDateHumanReadable }}</p>
    <p class="comment__content">{{ comment.text }}</p>
    <div class="comment__buttons">
      <div class="comments__interact-btn"
        v-if="comment.sub_comments && comment.sub_comments.length > 0" :style="{display: (dropdownOpen)? 'none' : 'flex'}">
          <button @click.stop="openDropdown"><i data-feather="chevron-down"></i></button><span>&nbsp;|&nbsp;</span>
      </div>
      <div class="comments__interact-btn"
        v-if="comment.sub_comments && comment.sub_comments.length > 0" :style="{display: (dropdownOpen)? 'flex' : 'none'}">
          <button @click.stop="closeDropdown"><i data-feather="chevron-up"></i></button><span>&nbsp;|&nbsp;</span>
      </div>
      <div class="comments__interact-btn"><button @click="answerOf(comment.id)">odpowiedz</button></div>
    </div>
    <section class="subcomments" v-if="comment.sub_comments && comment.sub_comments.length > 0 && dropdownOpen">
      <ul class="comments-list">
          <li class="comment" v-for="subcomment in comment.sub_comments" v-if="subcomment">
            <p class="comment__author"><strong>{{ userName(subcomment) }}</strong> - {{ subcomment.warrantyDateHumanReadable }}</p>
            <p class="comment__content">{{ subcomment.text }}</p>
          </li>
      </ul>
    </section>
  </li>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        props: {
          comment: {
            type: Object,
            required: true,
          },
        },
        data: () => {
          return {
            dropdownOpen : false,
            isLoading: false,
          };
        },
        methods: {
          openDropdown() {
            this.dropdownOpen = true;
          },
          closeDropdown() {
            this.dropdownOpen = false;
          },
          answerOf() {
            this.$emit('answer-to', this.comment);
          },
          userName(comment) {
            return (comment.signature)? comment.signature : comment.email;
          },
        },
        mounted() {
        },
        computed: {
        },
        components: {
          loading: Loading,
        },
    }
</script>
<style scoped>
  .post-comments >>> .vld-overlay {
    position: relative;
  }
  .subcomments >>> .comments-list {
    margin: 15px 0 0 0;
  }
  .subcomments .comments-list >>> .comment {
    border-left: 4px solid #07070733;
    padding-left: 15px;
    border-bottom: 0;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 3px;
  }
  .subcomments .comments-list .comment >>> .comment__author {
    font-size: 10px;
    margin-bottom: 10px;
  }
  .subcomments .comments-list .comment .comment__author >>> strong{
    font-size: 14px;
  }
</style>
