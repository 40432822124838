<template>
  <div class="post-comments" :id="idToScroll">
    <section class="add-comment gr-12">
      <h3 class="add-comment__title" :style="{display: (!answerCom)? 'block' : 'none'}">
        Dodaj komentarz
      </h3>
      <h3 class="add-comment__title answer" :style="{display: (answerCom)? 'block' : 'none'}">
        <span class="closeAnswer" @click="closeAnswer">X&nbsp;|</span>&nbsp;Odpowiedź na komentarz: <span v-if="answerCom"><strong>{{ answerCom.signature }}</strong> - {{ answerCom.warrantyDateHumanReadable }}</span>
      </h3>
      <google-re-captcha-v3
        ref="captcha" v-model="gRecaptchaResponse"
        :siteKey="this.siteKey"
        :id="'contact_us_id'"
        :inline="false"
        :action="'contact_us'">
      </google-re-captcha-v3>
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :on-cancel="null"
        :is-full-page="null"></loading>
      <form action="" class="add-comment__form" v-if="!isLoading">
        <p class="add-comment__info">Twój adres email nie zostanie opublikowany. Pola, których wypełnienie jest wymagane, są oznaczone symbolem *</p>
        <textarea v-model="message" name="message" id="message" class="add-comment__textarea" placeholder="treść wiadomości *"></textarea>
        <input v-model="name" name="name" id="name" type="text" class="add-comment__input" placeholder="podpis" />
        <input v-model="email" name="email" id="email" type="email" class="add-comment__input" placeholder="adres email *" />
        <input type="button" @click="sendComment" class="add-comment__input add-comment__submit" value="Opublikuj komentarz">
      </form>
      <FlashMessage></FlashMessage>
    </section>
    <section class="comments-list__box">
      <ul class="comments-list">
        <li class="comment" v-if="(!comments || comments.length === 0) && !isLoading">
          <p class="comment_content">Comments not found.</p>
        </li>
        <comment v-if="comments && comments.length > 0"
          v-for="(comment, key) in comments"
          :comment="comment"
          :key="key"
          @answer-to="answerTo"
        >
        </comment>
        <li class="comment" v-if="canLoadMore">
          <loading :active.sync="isLoading"
            :can-cancel="false"
            :on-cancel="null"
            :is-full-page="null"></loading>
          <input @click.stop="loadComments" type="button" class="add-comment__input add-comment__submit" value="Czytaj dalej">
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import GoogleReCaptchaV3 from './googlerecaptchav3/GoogleReCaptchaV3';
    import Comment from './comments/Comment';

    export default {
        props: {
          postid: {
            type: Number,
            required: true
          },
        },
        data: () => {
          return {
            page: 1,
            page_size: 10,
            canLoadMore: true,
            comments: [],
            isLoading: false,
            message: '',
            name: '',
            email: '',
            gRecaptchaResponse: null,
            siteKey: googleRecaotchaSiteKey,
            answerCom: null,
            idToScroll: 'postComments'
          };
        },
        methods: {
          closeAnswer() {
            this.answerCom = null;
          },
          answerTo(comment) {
            this.answerCom = comment;
            const el = document.getElementById(this.idToScroll);
            if (el) {
              el.scrollIntoView({
                behavior: 'smooth'
              });
            }
          },
          loadComments() {
            this.isLoading = true;
            axios({
              method: 'GET',
              url: `/comments/${this.postid}`,
              headers: {
                Accept: 'application/json'
              },
              params: {
                page: this.page,
                page_size: this.page_size,
              },
            }).then((response) => {
              if (response.status === 200 && response.data && response.data.comments){
                this.page = response.data.comments.current_page;
                if (this.comments.length <= 0) {
                  this.comments = response.data.comments.data;
                } else {
                  this.comments = this.comments.concat(response.data.comments.data);
                }

                if (response.data.comments.current_page >= response.data.comments.last_page) {
                    this.canLoadMore = false;
                } else {
                  this.page = this.page + 1;
                }
              }
              this.isLoading = false;
            }).catch((e) => {
              console.error(e);
              this.flashMessage.show({
                status: 'error',
                title: 'Uuups!',
                message: e,
                contentClass: 'notification-content',
              });
              this.isLoading = false;
              this.canLoadMore = false;
            });
          },
          sendComment() {
            this.isLoading = true;
            this.$refs.captcha.execute();
            axios({
              method: 'POST',
              url: `/comments/${this.postid}`,
              headers: {
                Accept: 'application/json',
              },
              data: {
                message: this.message,
                name: this.name,
                email: this.email,
                parent_id: (this.answerCom) ? this.answerCom.id : null,
                'g-recaptcha-response': this.gRecaptchaResponse,
              },
            }).then((response) => {
              if (response.status === 200 && response.data && response.data.comment){
                this.comments = [];
                this.loadComments();
                this.flashMessage.show({
                  status: 'success',
                  title: 'Dziękujemy!',
                  message: 'Komentarz został wysłany do akceptacji.',
                  contentClass: 'notification-content',
                });
                this.message = '';
                this.name = '';
                this.email = '';
                this.isLoading = false;
              }else {
                this.flashMessage.show({
                  status: 'error',
                  title: 'Błąd!',
                  message: response.data.error,
                  contentClass: 'notification-content',
                });
                this.isLoading = false;
              }
            })
            .catch((e) => {
              console.error(e);
              this.flashMessage.show({
                status: 'error',
                title: 'Uuups!',
                message: e,
                contentClass: 'notification-content',
              });
              this.isLoading = false;
            });
          },
      },
      mounted() {
        this.loadComments();
      },
      computed: {
      },
      components: {
        'google-re-captcha-v3': GoogleReCaptchaV3,
        loading: Loading,
        comment: Comment,
      },
    }
</script>
<style scoped>
  .post-comments{
    width: 100%;
  }
  .post-comments >>> .vld-overlay {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  ._vue-flash-msg-body_success ._vue-flash-msg-body__content{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .add-comment >>> .answer {
    font-size: 14px;
  }
  .answer >>> .closeAnswer {
    cursor: pointer;
    font-weight: bold;
  }
  .add-comment >>> .notification-content{
    padding: 30px 20px;
  }
  .add-comment >>> .notification-content h3{
    margin-bottom: 5px;
  }
</style>
