<template>
  <aside class="crypto hide show@lg">
      <div class="container" v-bind:class="{ 'not-visible': !visible }">
        <div class="row swiper-container crypto-slider">
          <ul class="swiper-wrapper crypto__list">
            <li class="crypto__el swiper-slide" v-for="coin in coins">
              <h4 class="crypto__valute"><img :src="coin.image" :alt="coin.name">{{coin.name}}</h4>
              <div class="crypto__info">
                <p class="crypto__value">${{coin.price}}</p>
                <p class="crypto__value" v-bind:class="{
                  'crypto__value--progress-up' : coin.percentage > 0,
                  'crypto__value--progress-down' : coin.percentage < 0
                  }"><i class="arrow" v-bind:class="{
                    'arrow--up': coin.percentage > 0,
                    'arrow--down': coin.percentage < 0, 
                  }"></i><span class="crypto__value-text">{{(coin.percentage < 0) ? coin.percentage*(-1) : coin.percentage }}%</span></p>
              </div>
            </li>
          </ul>
          <div class="swiper-pagination crypto-pagination"></div>
        </div>
      </div>
    </aside>
</template>

<script>
    export default {
        data: () => {
          return {
            coins: [],
            visible: false,
          };
        },
        mounted() {
            axios({
              method: 'GET',
              url: '/coins',
              headers: {
                Accept: 'application/json'
              },
            }).then((response) => {
              if (response.status === 200 && response.data){
                this.coins = response.data;
                setTimeout(() => {
                  var mySwiper = new Swiper ('.crypto-slider', {
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                    grabCursor: true,
                    loop: true,
                    autoplay: {
                      delay: 2500,
                      disableOnInteraction: false,
                    },
                    pagination: {
                      el: '.crypto-pagination',
                      type: 'progressbar',
                    },
                    breakpointsInverse: true,
                  });
                  this.visible = true;
                }, 1000);
              }
            });
        }
    }
</script>
