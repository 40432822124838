<template>
  <div class="from-category__box" v-if="visible" :id="idToScroll">
    <div class="from-category__anchor-box from-category__anchor-box--border-top">
      <a href="/category/kryptowaluty" class="category-name">Kryptowaluty</a>
      <a href="/category/kryptowaluty" class="custom-btn--read-more custom-btn custom-btn--red">zobacz więcej</a>
    </div>
    <div class="from-category__list-box" v-if="busy">
      <p>Loading...</p>
    </div>
    <div class="from-category__list-box" v-if="!busy">
      <p v-if="!currentPosts || currentPosts.length === 0">Posts not found.</p>
      <ul class="from-category__list" v-if="currentPosts && currentPosts.length !== 0">
        <li class="from-category__list-el from-category__list-el--with-img" v-if="currentPosts[0]">
          <a :href="`/post/${currentPosts[0].slug}`" class="from-category__img-anchor">
              <img class="from-category__list-image" :src="currentPosts[0].image" :alt="currentPosts[0].title">
          </a>
          <div class="from-category__flexbox">
            <a :href="`/post/${currentPosts[0].slug}`" class="post__title-anchor">
              <h3 class="post__title">{{ currentPosts[0].title }}</h3>
            </a>
            <ul class="tags tags--right" v-if="currentPosts[0].tags" v-html="generateTags(currentPosts[0].tags)"></ul>
            <p class="post__text post__text--no-spacing" v-html="currentPosts[0].excerpt"></p>
            <a :href="`/post/${currentPosts[0].slug}`" class="custom-btn custom-btn--read-more custom-btn--post custom-btn--vertical-spacing">czytaj dalej</a>
          </div>
        </li>
        <li class="from-category__list-el" v-if="currentPosts[1]">
          <a :href="`/post/${currentPosts[1].slug}`" class="post__title-anchor">
            <h3 class="post__title">{{ currentPosts[1].title }}</h3>
          </a>

          <ul class="tags tags--right" v-html="generateTags(currentPosts[1].tags)"></ul>
          <p class="post__text post__text--no-spacing" v-html="currentPosts[1].excerpt"></p>
          <a :href="`/post/${currentPosts[1].slug}`" class="custom-btn custom-btn--read-more custom-btn--post custom-btn--vertical-spacing">czytaj dalej</a>
        </li>
        <li class="from-category__list-el" v-if="currentPosts[2]">
          <a :href="`/post/${currentPosts[2].slug}`" class="post__title-anchor">
            <h3 class="post__title">{{ currentPosts[2].title }}</h3>
          </a>
          <ul class="tags tags--right" v-html="generateTags(currentPosts[2].tags)"></ul>
          <p class="post__text post__text--no-spacing" v-html="currentPosts[2].excerpt"></p>
          <a :href="`/post/${currentPosts[2].slug}`" class="custom-btn custom-btn--read-more custom-btn--post custom-btn--vertical-spacing">czytaj dalej</a>
        </li>
      </ul>
    </div>
    <ul class="pagination">
      <li class="pagination__el"><a href="#" @click.stop.prevent="prevPage" class="pagination__anchor"><i data-feather="arrow-left"></i></a></li>
      <li class="pagination__el" v-for="index in lastPage"><a @click.stop.prevent="setPage(index)" href="#" class="pagination__anchor"><span :class="{'custom-btn--red' : index === currentPage}">{{ index }}</span></a></li>
      <li class="pagination__el"><a href="#" @click.stop.prevent="nextPage" class="pagination__anchor"><i data-feather="arrow-right"></i></a></li>
    </ul>
  </div>
</template>

<script>
    export default {
        data: () => {
          return {
            idToScroll: 'homeKryptowaluty',
            categoryName: 'kryptowaluty',
            pageSize: 3,
            posts: [],
            visible: false,
            currentPage: 1,
            lastPage: 1,
            maxPages: 3,
            busy: false,
          };
        },
        methods: {
          prevPage() {
            this.busy = true;
            if (this.currentPage <= 1) {
              this.currentPage = this.lastPage;
            } else {
              this.currentPage = this.currentPage-1;
            }
            this.getPosts();
          },
          nextPage() {
            this.busy = true;
            if (this.currentPage >= this.lastPage) {
              this.currentPage = 1;
            } else {
              this.currentPage = this.currentPage+1;
            }
            this.getPosts();
          },
          setPage(index) {
            this.busy = true;
            if(index > this.lastPage) {
              this.currentPage = 1;
            } else {
              this.currentPage = index;
            }
            this.getPosts();
          },
          scrolltoSection() {
            const el = document.getElementById(this.idToScroll);
            if (el) {
              el.scrollIntoView({
                behavior: 'smooth'
              });
            }
          },
          getPosts() {
            if (!this.posts[this.currentPage]) {
              axios({
                method: 'GET',
                url: `/ajax/posts?cat_slug=${this.categoryName}&page_size=${this.pageSize}&page=${this.currentPage}`,
                headers: {
                  Accept: 'application/json'
                },
              }).then((response) => {
                if (response.status === 200 && response.data) {
                  this.posts[this.currentPage] = response.data.data.data;
                  this.lastPage = (response.data.data.last_page > this.maxPages) ? this.maxPages : response.data.data.last_page;
                }

                this.busy = false;
                this.visible = true;
                this.scrolltoSection();
              });
            } else {
              this.busy = false;
              this.scrolltoSection();
            }
          },
          generateTags(tags) {
            let result = '';

            tags.forEach((tag, index) => {
              result += `<li class="tags__tag-box"><a href="/tag/${tag.slug}" class="tags__tag-anchor">${tag.name}</a></li>`;

              if((index+1) < tags.length) {
                result += ',&nbsp;';
              }
            });

            return result;
          },
        },
        mounted() {
          this.busy = true;
          this.getPosts();
        },
        computed: {
          currentPosts() {
            return this.posts[this.currentPage];
          }
        },
    }
</script>
