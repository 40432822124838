<template>
  <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="100" infinite-scroll-delay="200">
    <div class="post search-result" v-for="video in videos" v-if="videos">
      <a :href="`/video/${video.slug}`" class="post__img-anchor hide show@lg">
        <iframe :src="video.video" class="post__img" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </a>
      <article class="post__content">
        <a :href="`/video/${video.slug}`" class="post__title-anchor">
          <h3 class="post__title">{{ video.title }}</h3>
        </a>
        <article class="author author--post">
          <ul class="tags" v-if="video.tags" v-html="generateTags(video.tags)"></ul>
        </article>
        <p class="post__text" v-html="video.excerpt"></p>
        <a :href="`/post/${video.slug}`" class="custom-btn custom-btn--read-more custom-btn--post">czytaj dalej</a>
      </article>
    </div>
    <div class="post search-result" v-if="busy">
      <img src="/images/line-loader.gif" class="loader">
    </div>
  </div>
</template>

<script>
    export default {
        props: {
          type: {
            type: String,
            default: 'category',
          },
          slug: {
            type: String,
            required: true,
          }
        },
        data: () => {
          return {
            pageSize: 5,
            videos: [],
            currentPage: 1,
            busy: false,
            loadedAllVideos: false,
            filter: 'cat_slug',
          };
        },
        methods: {
          loadMore() {
            if (!this.loadedAllVideos) {
              this.busy = true;
              this.getVideos();
            }
          },
          getVideos() {
            axios({
              method: 'GET',
              url: `/ajax/videos?${this.filter}=${this.slug}&page_size=${this.pageSize}&page=${this.currentPage}`,
              headers: {
                Accept: 'application/json'
              },
            }).then((response) => {
              if (response.status === 200 && response.data) {
                this.loadedAllVideos = response.data.data.current_page >= response.data.data.last_page;
                this.currentPage = response.data.data.current_page+1;
                this.videos = this.videos.concat(response.data.data.data);
              }

              this.busy = false;
              this.visible = true;
            });
          },
          generateTags(tags) {
            let result = '';

            tags.forEach((tag, index) => {
              result += `<li class="tags__tag-box"><a href="/tag/${tag.slug}" class="tags__tag-anchor">${tag.name}</a></li>`;

              if((index+1) < tags.length) {
                result += ',&nbsp;';
              }
            });

            return result;
          },
        },
        mounted() {
          if(this.type != 'category') {
            this.filter = 'tag_slug';
          }
          this.busy = true;
          this.getVideos();
        },
    }
</script>
<style scoped>
  .post >>> .loader {
    margin: auto;
  }
</style>
