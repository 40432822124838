window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (!token) {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.Vue = require('vue');
import FlashMessage from "@smartweb/vue-flash-message";
Vue.use(FlashMessage);
const infiniteScroll =  require('vue-infinite-scroll');
Vue.use(infiniteScroll);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('top-bar', require('./components/TopBar.vue').default);
Vue.component('home-kryptowaluty', require('./components/HomeKryptowaluty.vue').default);
Vue.component('home-szkolenia', require('./components/HomeSzkolenia.vue').default);
Vue.component('home-forex', require('./components/HomeForex.vue').default);
Vue.component('comments', require('./components/Comments.vue').default);
Vue.component('video-comments', require('./components/VideoComments.vue').default);
Vue.component('posts-list', require('./components/PostsList.vue').default);
Vue.component('video-list', require('./components/VideoList.vue').default);
Vue.component('contact-form', require('./components/ContactForm.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});


const feather = require('feather-icons')
let bigPostHref = document.getElementsByClassName('big-post__window-anchor')[0];
let categoryName = document.getElementsByClassName('category-name');
let tagName = document.getElementsByClassName('tags__tag-anchor');
let author = document.getElementsByClassName('author__name');

window.onload = () => {
  for (let i = 0; i < categoryName.length; i++) {
    categoryName[i].onclick = (e) => {
      e = event.currentTarget;
      href = e.innerText;
      bigPostHref.setAttribute('href', '/' + href);
    }
  }
  for (let i = 0; i < tagName.length; i++) {
    tagName[i].onclick = (e) => {
      e = event.currentTarget;
      href = e.innerText;
      bigPostHref.setAttribute('href', '/' + href);
    }
  }
  for (let i = 0; i < author.length; i++) {
    author[i].onclick = (e) => {
      e = event.currentTarget;
      href = e.getAttribute('data-href');
      bigPosetHref.setAttribute('href', '#' + href);
    }

  }

  for (let i = 0; i < document.getElementsByClassName('hamburger-icon').length; i++) {
      document.getElementsByClassName('hamburger-icon')[i].onclick = () => {
        document.getElementsByClassName('menu-mobile')[0].classList.toggle('active')
        for (let k = 0; k < document.getElementsByClassName('hamburger-icon').length; k++) {
            document.getElementsByClassName('hamburger-icon')[k].classList.toggle('active');
        }
      }

  }

  var mySwiper2 = new Swiper ('.authors-slider', {
    slidesPerView: 1,
    grabCursor: true,
    effect: 'coverflow',
    speed: 500,
    loop: true,
    pagination: {
      el: '.authors-pagination',
      type: 'bullets',
      clickable: true,
      dynamicBullets: true,
    },
    navigation: {
      nextEl: '.authors-button-next',
      prevEl: '.authors-button-prev',
    },
    on: {
      init: () => {
        if (authorImages && authorImages.length > 0) {
          setTimeout(() => {
            const bullets = document.querySelectorAll('.authors-pagination .swiper-pagination-bullet');
            if (bullets.length > 0) {
              bullets.forEach((bullet, index) => {
                bullet.style.backgroundImage = `url(\'${authorImages[index]}\')`;
                bullet.style.backgroundSize = 'contain';
              });
            }
          }, 500);
        }
      },
    },
  });
  setTimeout(() => {
    feather.replace();
  }, 500);
}
