<template>
  <div class="post-comments" :id="idToScroll">
    <section class="add-comment gr-12">
      <h3 class="add-comment__title">
        Napisz do Nas!
      </h3>
      <google-re-captcha-v3
        ref="captcha" v-model="gRecaptchaResponse"
        :siteKey="this.siteKey"
        :id="'contact_us_id'"
        :inline="false"
        :action="'contact_us'">
      </google-re-captcha-v3>
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :on-cancel="null"
        :is-full-page="null"></loading>
      <form action="" class="add-comment__form" v-if="!isLoading">
        <p class="add-comment__info">Pola, których wypełnienie jest wymagane, są oznaczone symbolem *</p>
        <input v-model="title" name="title" id="title" type="text" class="add-comment__textarea" placeholder="temat" />
        <textarea v-model="message" name="message" id="message" class="add-comment__textarea" placeholder="treść wiadomości *"></textarea>
        <input v-model="name" name="name" id="name" type="text" class="add-comment__input" placeholder="podpis" />
        <input v-model="email" name="email" id="email" type="email" class="add-comment__input" placeholder="adres email *" />
        <input type="button" @click="sendMessage" class="add-comment__input add-comment__submit" value="Wyślij wiadomość">
      </form>
      <FlashMessage></FlashMessage>
    </section>
  </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import GoogleReCaptchaV3 from './googlerecaptchav3/GoogleReCaptchaV3';

    export default {
        data: () => {
          return {
            isLoading: false,
            title: '',
            message: '',
            name: '',
            email: '',
            gRecaptchaResponse: null,
            siteKey: googleRecaotchaSiteKey,
            idToScroll: 'contactForm'
          };
        },
        methods: {
          sendMessage() {
            this.isLoading = true;
            this.$refs.captcha.execute();
            axios({
              method: 'POST',
              url: `/contact-form`,
              headers: {
                Accept: 'application/json',
              },
              data: {
                title: this.title,
                message: this.message,
                name: this.name,
                email: this.email,
                'g-recaptcha-response': this.gRecaptchaResponse,
              },
            }).then((response) => {
              if (response.status === 200 && response.data && response.data.comment){
                this.flashMessage.show({
                  status: 'success',
                  title: 'Dziękujemy!',
                  message: 'Wiadomość została wysłana do akceptacji.',
                  contentClass: 'notification-content',
                });
                this.title = '';
                this.message = '';
                this.name = '';
                this.email = '';
                this.isLoading = false;
              }else {
                this.flashMessage.show({
                  status: 'error',
                  title: 'Błąd!',
                  message: response.data.error,
                  contentClass: 'notification-content',
                });
                this.isLoading = false;
              }
            })
            .catch((e) => {
              console.error(e);
              this.flashMessage.show({
                status: 'error',
                title: 'Uuups!',
                message: e,
                contentClass: 'notification-content',
              });
              this.isLoading = false;
            });
          },
      },
      mounted() {},
      computed: {},
      components: {
        'google-re-captcha-v3': GoogleReCaptchaV3,
        loading: Loading,
      },
    }
</script>
<style scoped>
  .post-comments{
    width: 100%;
  }
  .post-comments >>> .vld-overlay {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  ._vue-flash-msg-body_success ._vue-flash-msg-body__content{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .add-comment >>> .notification-content{
    padding: 30px 20px;
  }
  .add-comment >>> .notification-content h3{
    margin-bottom: 5px;
  }
</style>
